/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {faPhoneSquareAlt, faAt, faBrowser, faExternalLinkAlt} from "@fortawesome/pro-solid-svg-icons";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    h4: "h4",
    p: "p",
    a: "a",
    h3: "h3"
  }, _provideComponents(), props.components), {FontAwesomeIcon} = _components;
  if (!FontAwesomeIcon) _missingMdxReference("FontAwesomeIcon", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, null, "North Lincolnshire Library and Information Services"), "\n", React.createElement("div", {
    className: "w-full md:w-2/5 md:pl-6 mb-4 md:mb-0 text-right p-4 z-40 md:ml-6 bg-gray-700 rounded-r-lg md:rounded-r-none rounded-l-lg text-white inverse float-right inline-block inset-0"
  }, React.createElement(_components.h4, null, "Contact details"), React.createElement(_components.p, null, "If you need to contact the service, you can use the following details"), React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "tel:+441724860161"
  }, React.createElement(FontAwesomeIcon, {
    icon: faPhoneSquareAlt,
    size: "sm"
  }), " : 01724 860161")), React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "mailto:janet.stopper@northlincs.gov.uk"
  }, React.createElement(FontAwesomeIcon, {
    icon: faAt,
    size: "sm"
  }), " : Library and Information Services")), React.createElement("a", {
    href: "https://www.northlincs.gov.uk/schools-libraries-and-learning/mobile-library-service/",
    target: "_blank",
    rel: "noopener,noreferrer"
  }, React.createElement(FontAwesomeIcon, {
    icon: faBrowser
  }), " : https://www.northlincs.gov.uk/schools-libraries-and-learning/mobile-library-service ", React.createElement(FontAwesomeIcon, {
    icon: faExternalLinkAlt,
    size: "xs"
  }))), "\n", React.createElement(_components.p, null, "Please see table below for the stop off times at a location near to you."), "\n", React.createElement(_components.h3, null, "Burton upon Stather"), "\n", React.createElement(_components.p, null, "The mobile library visits Burton every Tuesday. It stops at a number of sites in the village, but the majority of its time is spent in the car park of the medical centre on Norfolk Avenue."), "\n", React.createElement("div", {
    style: {
      display: 'inline-block'
    }
  }, React.createElement("table", {
    className: "table"
  }, React.createElement("thead", null, React.createElement("tr", null, React.createElement("th", null, "Stop"), React.createElement("th", null, "Location"), React.createElement("th", null, "Time"))), React.createElement("tbody", null, React.createElement("tr", null, React.createElement("td", null, "1"), React.createElement("td", null, "Barnstone Way"), React.createElement("td", null, "09:30 - 10:00")), "\n", React.createElement("tr", null, React.createElement("td", null, "2"), React.createElement("td", null, "Stather Road"), React.createElement("td", null, "10:05 - 10:30")), "\n", React.createElement("tr", null, React.createElement("td", null, "3"), React.createElement("td", null, "Holme Drive"), React.createElement("td", null, "10:35 - 11:00")), "\n", React.createElement("tr", null, React.createElement("td", null, "4"), React.createElement("td", null, "Tee Lane"), React.createElement("td", null, "11:05 - 11:30")), "\n", React.createElement("tr", null, React.createElement("td", null, "5"), React.createElement("td", null, "Medical Centre"), React.createElement("td", null, "11:35 - 12:35")), "\n", React.createElement("tr", null, React.createElement("td", null, "6"), React.createElement("td", null, "Burton-upon-Stather Primary School"), React.createElement("td", null, "13:20 - 14:20"))))), "\n", React.createElement(_components.h3, null, "Normanby"), "\n", React.createElement(_components.p, null, "Every 3 weeks on a Monday from 11:55 to 12:25"), "\n", React.createElement(_components.p, null, "Location: Main Street"), "\n", React.createElement(_components.h3, null, "Thealby"), "\n", React.createElement(_components.p, null, "Every 3 weeks on a Wednesday from 15:00 to 15:15"), "\n", React.createElement(_components.p, null, "Location: Normanby Road"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
